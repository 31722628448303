import React, { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { InputSearch } from '../../../components/Input/InputSearch'
import { debounce } from '@mui/material'
import { useActions } from '../../../hooks/useActions'
import { useTypeSelector } from '../../../hooks/useTypeSelector'
import { OperationsFilter } from '../../../components/Operations/OperationsFilter'
import { BookingsTabs } from './BookingsTabs'
import styles from '../../../styles/Operations.module.css'

export const BookingsContent: React.FC = () => {
  const { filterWarehouses } = useTypeSelector((state) => state.warehouses)
  const { managers } = useTypeSelector((state) => state.operations)
  const { filters } = useTypeSelector((state) => state.bookings)
  const {
    setBooking,
    setBookings,
    setBookingFilters,
    getProductsThunkCreator,
    getManagersThunkCreator,
    getFilterWarehousesThunkCreator,
    setBookingProducts,
    setOrderProducts,
    setProducts,
    setBookingAudit
  } = useActions()
  const [query, setQuery] = useState<string>(filters.sku || '')

  const debouncedSearch = useCallback(
    debounce((sku: string) => setBookingFilters({ sku }), 800),
    []
  )

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/[^\d]/g, '')
    setQuery(value)
    debouncedSearch(value)
  }

  useEffect(() => {
    setBooking(null)
    setBookings([])
    setBookingAudit([])
    setBookingProducts([])
    setOrderProducts([])
    setProducts([])
    getProductsThunkCreator(null, null)
    !filterWarehouses.length && getFilterWarehousesThunkCreator()
    !managers.length && getManagersThunkCreator()
  }, [])

  return (
    <div className={styles.container}>
      <InputSearch onChange={handleSearch} value={query} placeholder="Введите номер бронирования или Битрикс Id" />
      <OperationsFilter type="bookings" />
      <BookingsTabs />
    </div>
  )
}
