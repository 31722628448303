import React from 'react'
import { OrderAuditChangedFieldType, OrderAuditDiff } from '../../types/ordersTypes'
import { Box } from '@mui/material'
import { Status } from '../Status/Status'
import { ArrowToIcon } from '../../infrasturcture/icons'

const fieldNames: Record<keyof OrderAuditChangedFieldType, string> = {
  status: 'Статус',
  bitrix_id: 'Битрикс Id',
  ready_at: 'Планируемая дата готовности',
  comment: 'Комментарий'
}

interface OrderAuditChangesProps {
  changes: OrderAuditDiff['changes']
}

export const OrderAuditChanges: React.FC<OrderAuditChangesProps> = ({ changes }) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: { xs: '12px', md: '14px' } }}>
      {changes.map(({ field, from, to }, i) => (
        <Box key={i} sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', gap: '4px 16px' }}>
          {fieldNames[field]}:<Box>{field === 'status' ? <Status style={{ margin: 0 }} type={from!} /> : from} </Box>
          <ArrowToIcon style={{ margin: '0 -8px' }} />
          <Box>{field === 'status' ? <Status style={{ margin: 0 }} type={to!} /> : to} </Box>
        </Box>
      ))}
    </Box>
  )
}
