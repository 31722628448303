import { ThunkAction } from 'redux-thunk'
import { RootState } from '..'
import OrdersApi from '../../api/OrdersApi'
import {
  AddOrderProduct,
  SetOrdersPostingProductsAction,
  BackOrderPosting,
  SetOrderProducts,
  DeleteOrdersPosting,
  MinusOrderProduct,
  MinusOrdersPostingProduct,
  Order,
  OrderPosting,
  OrderPostingProduct,
  ProductInOrder,
  OrdersAction,
  OrdersActionTypes,
  PlusOrderProduct,
  PlusOrdersPostingProduct,
  SetOrder,
  SetOrderFilters,
  SetOrderProductError,
  SetOrdersPostingAction,
  SetOrederLoaded,
  UpdateOrderProduct,
  SetOrdersErrorsAction,
  OrderFilters,
  SetOrders,
  OrderAudit,
  SetOrderAuditAction
} from '../../types/ordersTypes'
import { SetAuthErrorAction } from '../../types/userTypes'
import { setAuthError } from './userActions'
import { NavigateFunction } from 'react-router-dom'
import { ErrorMessages } from '../../types/errorTypes'
import { errorsHandling } from '../../infrasturcture/utils/errorsHandling'

type ThunkType = ThunkAction<void, RootState, unknown, OrdersAction | SetAuthErrorAction>

export const setOrders = (payload: Order[]): SetOrders => ({
  type: OrdersActionTypes.SET_ORDERS,
  payload
})

const setOrderLoaded = (payload: boolean): SetOrederLoaded => ({
  type: OrdersActionTypes.SET_ORDER_LOADED,
  payload
})

export const setOrder = (payload: Order | null): SetOrder => ({
  type: OrdersActionTypes.SET_ORDER,
  payload
})

export const setOrderAudit = (payload: OrderAudit[]): SetOrderAuditAction => ({
  type: OrdersActionTypes.SET_ORDER_AUDIT,
  payload
})

export const addOrderProduct = (payload: ProductInOrder): AddOrderProduct => ({
  type: OrdersActionTypes.ADD_ORDER_PRODUCT,
  payload
})

export const plusOrderProduct = (payload: string): PlusOrderProduct => ({
  type: OrdersActionTypes.PLUS_ORDER_PRODUCT,
  payload
})

export const minusOrderProduct = (payload: string): MinusOrderProduct => ({
  type: OrdersActionTypes.MINUS_ORDER_PRODUCT,
  payload
})

export const setOrderProducts = (payload: ProductInOrder[]): SetOrderProducts => ({
  type: OrdersActionTypes.SET_ORDER_PRODUCTS,
  payload
})

export const setOrdersPostingProducts = (payload: OrderPostingProduct[]): SetOrdersPostingProductsAction => ({
  type: OrdersActionTypes.SET_ORDERS_POSTING_PRODUCTS,
  payload
})

export const setOrdersPosting = (payload: OrderPosting[]): SetOrdersPostingAction => ({
  type: OrdersActionTypes.SET_ORDERS_POSTING,
  payload
})

export const backOrderPosting = (payload: OrderPosting): BackOrderPosting => ({
  type: OrdersActionTypes.BACK_ORDER_POSTING,
  payload
})

export const deleteOrdersPosting = (payload: string): DeleteOrdersPosting => ({
  type: OrdersActionTypes.DELETE_ORDERS_POSTING,
  payload
})

export const plusOrdersPostingProduct = (orderId: string, productId: string): PlusOrdersPostingProduct => ({
  type: OrdersActionTypes.PLUS_ORDERS_POSTING_PRODUCT,
  payload: { orderId, productId }
})

export const minusOrdersPostingProduct = (orderId: string, productId: string): MinusOrdersPostingProduct => ({
  type: OrdersActionTypes.MINUS_ORDERS_POSTING_PRODUCT,
  payload: { orderId, productId }
})

export const setOrderProductError = (payload: string | null): SetOrderProductError => ({
  type: OrdersActionTypes.SET_ORDER_PRODUCT_ERROR,
  payload
})

export const updateOrderProduct = (payload: ProductInOrder): UpdateOrderProduct => ({
  type: OrdersActionTypes.UPDATE_ORDER_PRODUCT,
  payload
})

export const setOrderFilters = (payload: Partial<OrderFilters>): SetOrderFilters => ({
  type: OrdersActionTypes.SET_ORDER_FILTERS,
  payload
})

export const setOrdersErrors = (payload: ErrorMessages): SetOrdersErrorsAction => ({
  type: OrdersActionTypes.SET_ORDERS_ERRORS,
  payload
})

export const getOrdersThunkCreator = (limit = 50, page = 1, filters: OrderFilters): ThunkType => {
  return async (dispatch) => {
    try {
      dispatch(setOrderLoaded(true))
      const data = await OrdersApi.getOrders(limit, page, filters)
      dispatch({ type: OrdersActionTypes.GET_ORDERS, payload: data })
    } catch (e: any) {
      dispatch(setAuthError(e.response.data.messages))
    }
  }
}

export const getOrderThunkCreator = (id: string, navigate: NavigateFunction): ThunkType => {
  return async (dispatch) => {
    try {
      dispatch(setOrderLoaded(true))
      const data = await OrdersApi.getOrder(id)
      dispatch({ type: OrdersActionTypes.GET_ORDER, payload: data })
    } catch (e: any) {
      if ([403, 404, 503].includes(e.response.status)) {
        return navigate(`/${e.response.status}`)
      }
      dispatch(setAuthError(e.response.data.messages))
    }
  }
}

export const getOrdersPostingThunkCreator = (warehouseId: string): ThunkType => {
  return async (dispatch) => {
    try {
      dispatch(setOrderLoaded(true))
      const data = await OrdersApi.getOrderPosting(warehouseId)
      dispatch({ type: OrdersActionTypes.GET_ORDERS_POSTING, payload: data })
    } catch (e: any) {
      dispatch(setAuthError(e?.response?.data?.messages))
    }
  }
}

export const getOrderAuditThunkCreator = (id: string): ThunkType => {
  return async (dispatch) => {
    try {
      const data = await OrdersApi.getOrderAudit(id)
      dispatch({ type: OrdersActionTypes.GET_ORDER_AUDIT, payload: data })
    } catch (e: any) {
      dispatch(setAuthError(e?.response?.data?.messages))
    }
  }
}

export const editOrderThunkCreator = (order: Order, cb: () => void, navigate: NavigateFunction): ThunkType => {
  return async (dispatch) => {
    try {
      await OrdersApi.editOrder(order)
      cb()
    } catch (e: any) {
      if (e.response.status === 422) return dispatch(setOrdersErrors(errorsHandling(e.response)))
      if ([403, 404, 503].includes(e.response.status)) return navigate(`/${e.response.status}`)
      dispatch(setAuthError(e.response.data.messages))
    }
  }
}

export const createOrderThunkCreator = (order: any, cb: () => void, navigate: NavigateFunction): ThunkType => {
  return async (dispatch) => {
    try {
      await OrdersApi.createOrder(order)
      cb()
    } catch (e: any) {
      if (e.response.status === 422) return dispatch(setOrdersErrors(errorsHandling(e.response)))
      if ([403, 404, 503].includes(e.response.status)) return navigate(`/${e.response.status}`)
      dispatch(setAuthError(e.response.data.messages))
    }
  }
}
