import { Box, Button } from '@mui/material'
import React, { useMemo, useState } from 'react'
import { DownArrowIcon } from '../../infrasturcture/icons'
import { useActions } from '../../hooks/useActions'
import { useTypeSelector } from '../../hooks/useTypeSelector'
import { VgTable, VgTableColumnType } from '../VgTable/VgTable'
import { StyledCell } from '../../infrasturcture/theme/styled'
import { formatDate } from '../../infrasturcture/utils/formatDate'
import { Option } from '../Options/Option'
import { OrderAuditChangedFieldType, OrderAuditDiff } from '../../types/ordersTypes'
import { useMediaQueryContext } from '../../contexts/MediaQueryContext'
import { OrderAuditChanges } from './OrderAuditChanges'

const orderAuditColumns: VgTableColumnType<OrderAuditDiff>[] = [
  {
    key: 'name',
    title: 'Наименование изменения',
    width: '25%'
  },
  {
    key: 'audited_at',
    title: 'Дата',
    render: (_, audit) => <StyledCell variant="green">{formatDate(audit.audited_at)}</StyledCell>,
    width: '15%'
  },
  {
    key: 'audited_by',
    title: 'Пользователь',
    render: (_, audit) => <Option text={audit.audited_by} subText={audit.audited_company} fontWeight={500} variant="table" />,
    width: '20%'
  },
  {
    key: 'changes',
    title: 'Изменения',
    render: (_, { changes }) => <OrderAuditChanges changes={changes} />,
    width: '40%'
  }
]

const auditFieldsKeys: Array<keyof OrderAuditChangedFieldType> = ['status', 'ready_at', 'bitrix_id', 'comment']

interface OrderAuditProps {
  type: 'booking' | 'order'
}

export const OrderAudit: React.FC<OrderAuditProps> = ({ type }) => {
  const { isMobile } = useMediaQueryContext()
  const { getOrderAuditThunkCreator, getBookingAuditThunkCreator } = useActions()
  const { order, orderAudit } = useTypeSelector((state) => state.orders)
  const { booking, bookingAudit } = useTypeSelector((state) => state.bookings)

  const [isShow, setIsShow] = useState<boolean>(false)
  const isBooking = type === 'booking'
  const auditsData = isBooking ? bookingAudit : orderAudit

  const audits = useMemo<OrderAuditDiff[]>(
    () =>
      auditsData.map((item, index) => {
        const prevItem = auditsData[index - 1]
        return {
          ...item,
          changes: auditFieldsKeys.reduce<OrderAuditDiff['changes']>((arr, key) => {
            const isChanged = prevItem?.[key] !== undefined && prevItem[key] !== item[key]
            return isChanged ? [...arr, { field: key, from: prevItem[key], to: item[key] }] : arr
          }, [])
        }
      }),
    [auditsData]
  )

  const handleClick = () => {
    const auditId = auditsData[0]?.order_id
    const currentOrder = isBooking ? booking : order
    if (currentOrder && currentOrder.id !== auditId) {
      isBooking ? getBookingAuditThunkCreator(currentOrder.id) : getOrderAuditThunkCreator(currentOrder.id)
    }
    setIsShow((prev) => !prev)
  }

  return (
    <Box sx={{ mt: { xs: '32px', md: '40px' } }}>
      <Button
        onClick={handleClick}
        color="primary"
        endIcon={<DownArrowIcon />}
        sx={{
          '& .MuiButton-endIcon': {
            transform: `rotate(${isShow ? 180 : 0}deg)`
          }
        }}
      >
        История изменений
      </Button>
      {isShow && !!audits.length && (
        <VgTable style={{ marginTop: 20 }} columns={orderAuditColumns} data={audits} mobileMode={isMobile} />
      )}
    </Box>
  )
}
