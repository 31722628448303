import { formatISO } from 'date-fns'
import { instance } from '../infrasturcture/http'
import { getQueryString } from '../infrasturcture/utils/getQueryString'
import { Order, OrderFilters } from '../types/ordersTypes'

export default class OrdersApi {
  static async getOrders(
    limit = 50,
    page = 1,
    { status, managers, products, warehouses, startDate, endDate, sku, quickFilter }: OrderFilters
  ) {
    const response = await instance.get('orders', {
      params: {
        limit,
        page,
        status,
        managers: getQueryString(managers, 'id') || null,
        products: getQueryString(products, 'id') || null,
        warehouses: getQueryString(warehouses, 'id') || null,
        date: startDate ? `${formatISO(startDate)},${endDate ? formatISO(endDate) : formatISO(startDate)}` : null,
        sku: sku || null,
        quickFilter: quickFilter || null
      }
    })
    return response.data.data
  }

  static async getOrder(id: string) {
    const response = await instance.get(`orders/${id}`)
    return response.data.data
  }

  static async createOrder(order: Order) {
    const response = await instance.post('orders', order)
    return response.data.data
  }

  static async getOrderPosting(warehouseId: string) {
    const response = await instance.get('orders-posting', {
      params: { warehouseId }
    })
    return response.data.data
  }

  static async editOrder(order: Order) {
    const response = await instance.post(`orders/${order.id}`, order)
    return response.data.data
  }

  static async getOrderAudit(id: string) {
    const response = await instance.get(`orders/${id}/audit`)
    return response.data.data
  }
}
